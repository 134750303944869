import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {Link, Redirect, RouteComponentProps} from "react-router-dom";
import AppConfig from "../../entities/AppConfig";
import ApplicationUser from "../../entities/ApplicationUser";
import ProPlan from "../../entities/ProPlan";
import ProSubscriptionPromoCode from "../../entities/ProSubscriptionPromoCode";
import SSLLogo from "../../resources/ssl.png";
import {AuthenticationService} from "../../services/AuthenticationService";
import {ProSubscriptionService} from "../../services/ProSubscriptionService";
import {FieldValidationError, ServerModelValidationResponse, ServerResponse,} from "../../services/ServiceHelper";
import {EventTracking} from "../../utilities/EventTracking";
import {ProPlanIdDetermination} from "../../utilities/ProPlanIdDetermination";
import {ProSubscriptionTier} from "../../entities/ProSubscription";

interface Props extends RouteComponentProps<any> {
  config: AppConfig;
  user: ApplicationUser | null;
  handleUpdateUser: (user: ApplicationUser | null) => void;
}

type State = {
  proPlans: ProPlan[];
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  agreeToTerms: boolean;
  selectedProPlanId: string;
  enteredPromoCode: string;
  appliedPromoCode?: ProSubscriptionPromoCode;
  redirectToSubscribe: boolean;
  enableSubmit: boolean;
  errors: FieldValidationError[];
  passwordStrengthWidth: string;
  passwordStrengthClass: string;
  passwordStrengthLabel: string;
  promoCodeCollapsed: boolean;
  excludeTrial: boolean;
  proSubscriptionSelectionExpanded: boolean;
  includeBasicTrial: boolean;
};

export default class SubscribeCreateAccount extends React.Component<
  Props,
  State
> {
  redirectState: any = {};
  dataLayer = ((window as any).dataLayer = (window as any).dataLayer || []);

  constructor(props: Props) {
    super(props);
    this.state = {
      proPlans: [],
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      agreeToTerms: false,
      selectedProPlanId: "AnnualPlus",
      enteredPromoCode: "",
      appliedPromoCode: undefined,
      redirectToSubscribe: false,
      enableSubmit: true,
      errors: [],
      passwordStrengthWidth: "0%",
      passwordStrengthClass: "",
      passwordStrengthLabel: "6-25 characters",
      promoCodeCollapsed: true,
      excludeTrial: false,
      proSubscriptionSelectionExpanded: true,
      includeBasicTrial: false,
    };
  }

  async componentDidMount() {
    document.title = "SermonCentral Payments - Create Account";

    let redirectState = this.props.location.state;

    // Unlikely scenario, but user shouldn't be on this page unless they've been redirected
    // and aren't signed in, so redirect back to /subscribe
    if (!redirectState || this.props.user) {
      this.setState({
        redirectToSubscribe: true,
      });
    } else {
      const email = (redirectState as any).email || "";
      const firstName = (redirectState as any).firstName || "";
      const lastName = (redirectState as any).lastName || "";
      const phoneNumber = (redirectState as any).phoneNumber || "";
      const enteredPromoCode = (redirectState as any).utm_promo || "";

      let selectedProPlanId = "";

      if ((redirectState as any).pre_selected_plan) {
        selectedProPlanId = ProPlanIdDetermination.getProPlanNameById(
          (redirectState as any).pre_selected_plan
        );
      }

      let excludeTrial = false;
      if (
        (redirectState as any).excludeTrial &&
        (redirectState as any).excludeTrial === "1"
      ) {
        excludeTrial = true;
      }

      let includeBasicTrial = false;
      if (
          (redirectState as any).includeBasicTrial &&
          (redirectState as any).includeBasicTrial === "1"
      ) {
        includeBasicTrial = true;
      }

      delete (redirectState as any).email;
      delete (redirectState as any).firstName;
      delete (redirectState as any).lastName;
      delete (redirectState as any).phoneNumber;
      delete (redirectState as any).pre_selected_plan;
      delete (redirectState as any).utm_promo;
      delete (redirectState as any).excludeTrial;
      delete (redirectState as any).includeBasicTrial;

      this.redirectState = redirectState;
      this.setState({
        email: email,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        selectedProPlanId: selectedProPlanId,
        proSubscriptionSelectionExpanded: selectedProPlanId.length === 0,
        enteredPromoCode: enteredPromoCode,
        excludeTrial,
        includeBasicTrial,
      });

      var proPlans = await ProSubscriptionService.getProPlans();
      this.setState({ proPlans: proPlans });

      if (enteredPromoCode) {
        await this.validatePromoCode();
      }

      EventTracking.CreateAccount.onPageLoad();
    }
  }

  componentDidUpdate() {
    this.dataLayer.push({ event: "optimize.activate" });
  }

  handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let passwordStrengthWidth = "0%";
    let passwordStrengthClass = "";
    let passwordStrengthLabel = "6-25 characters";

    const passwordLength = e.target.value.length;
    if (passwordLength > 0 && passwordLength < 6) {
      passwordStrengthWidth = "33%";
      passwordStrengthClass = "progress-bar bg-danger";
      passwordStrengthLabel = "Too Short";
    } else if (passwordLength >= 6 && passwordLength < 8) {
      passwordStrengthWidth = "66%";
      passwordStrengthClass = "progress-bar bg-warning";
      passwordStrengthLabel = "Could be Stronger";
    } else if (passwordLength >= 8) {
      passwordStrengthWidth = "100%";
      passwordStrengthClass = "progress-bar bg-success";
      passwordStrengthLabel = "Strong Password";
    }
    this.setState({
      password: e.target.value,
      passwordStrengthWidth,
      passwordStrengthClass,
      passwordStrengthLabel,
    });
  };

  handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.state.enableSubmit) {
      return;
    }

    if (
      this.state.firstName &&
      this.state.lastName &&
      this.state.email &&
      this.state.password &&
      this.state.agreeToTerms
    ) {
      try {
        this.setState({ enableSubmit: false });

        // 8/29/2019 Caleb Requested: https://trello.com/c/pMkyLi1A/708-m-l-add-custom-events-to-css-selectors-to-payment-pathway-spa
        // I'm just commenting this out now, probably we will return it.
        // if (this.state.phoneNumber.trim().length === 0) {
        //     const serverError: FieldValidationError = { field: "PhoneNumber", errors: ['Phone number cannot be empty'] };
        //     this.setState({ errors: [serverError], enableSubmit: true });
        //     return;
        // }

        const response = await AuthenticationService.register(
          this.state.firstName,
          this.state.lastName,
          this.state.email,
          this.state.password
        );
        const excludeTrail = this.state.excludeTrial;
        const includeBasicTrial = this.state.includeBasicTrial;
        if (ServerResponse.isSuccess<ApplicationUser>(response)) {
          EventTracking.CreateAccount.onAccountCreated();

          let redirectState = this.redirectState;
          (redirectState as any).phoneNumber = this.state.phoneNumber;
          (redirectState as any).hidePhoneNumberCapture = true;
          (redirectState as any).selectedProPlanId =
            this.state.selectedProPlanId || "AnnualPlus";
          (redirectState as any).enteredPromoCode = this.state.enteredPromoCode;
          (redirectState as any).cameFromCreateAccount = true;
          (redirectState as any).excludeTrial = excludeTrail ? "1" : "0";
          (redirectState as any).includeBasicTrial = includeBasicTrial ? "1" : "0";
          this.redirectState = redirectState;

          this.props.handleUpdateUser(response.data);
          this.setState({ redirectToSubscribe: true });
        } else if (
          ServerModelValidationResponse.isServerModelValidationResponse(
            response
          )
        ) {
          if (response.valid) {
            const serverError: FieldValidationError = {
              field: "",
              errors: ["An unknown error occurred. Please try again."],
            };
            this.setState({ errors: [serverError], enableSubmit: true });
          } else {
            this.setState({ errors: response.errors, enableSubmit: true });
          }
        } else if (ServerResponse.isError(response)) {
          const serverError: FieldValidationError = {
            field: "",
            errors: [response.message],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        } else {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["An unknown error occurred. Please try again."],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        }
      } catch (errorResult) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      }
    }
  };

  handlePromoCodeFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await this.validatePromoCode();
  };

  async validatePromoCode() {
    if (!this.state.enableSubmit) {
      return;
    }

    if (this.state.enteredPromoCode) {
      try {
        this.setState({ enableSubmit: false });
        EventTracking.CreateAccount.onPromoCodeSubmitted();
        let proPlan = this.state.proPlans.find(
          (p) => p.id === this.state.selectedProPlanId
        );
        if (!proPlan) {
          proPlan = this.state.proPlans.find((p) => p.id === "AnnualPlus")!;
        }

        const response = await ProSubscriptionService.validatePromoCode(
          this.state.enteredPromoCode,
          proPlan.term,
          proPlan.tier
        );

        if (ServerResponse.isSuccess<ProSubscriptionPromoCode>(response)) {
          this.setState({
            appliedPromoCode: response.data,
            enableSubmit: true,
            errors: [],
          });
        } else if (
          ServerModelValidationResponse.isServerModelValidationResponse(
            response
          )
        ) {
          if (response.valid) {
            const serverError: FieldValidationError = {
              field: "",
              errors: ["An unknown error occurred. Please try again."],
            };
            this.setState({ errors: [serverError], enableSubmit: true });
          } else {
            this.setState({ errors: response.errors, enableSubmit: true });
          }
        } else if (ServerResponse.isError(response)) {
          const serverError: FieldValidationError = {
            field: "",
            errors: [response.message],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        } else {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["An unknown error occurred. Please try again."],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        }
      } catch (errorResult) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      }
    }
  }

  onDeselectPlan(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    this.setState({ proSubscriptionSelectionExpanded: true });
  }

  onPlanChange(plan: ProPlan) {
    if (this.state.selectedProPlanId === "") {
      EventTracking.CreateAccount.onSelectPlan(`${plan.name}-${plan.id}`);
    } else {
      EventTracking.CreateAccount.onChangePlan(`${plan.name}-${plan.id}`);
    }

    this.setState({
      selectedProPlanId: plan.id,
      proSubscriptionSelectionExpanded: false,
    });
  }

  isTrialAvailable = (plan: ProPlan): boolean => {

    let plusTrialAvailable =
        plan.tier === ProSubscriptionTier.Plus &&
        plan.allowTrial &&
        !this.state.appliedPromoCode &&
        !(this.props.user && this.props.user.hasEverHadProSubscription) &&
        !(this.state.excludeTrial);

    let basicTrialAvailable =
        plan.tier === ProSubscriptionTier.Basic &&
        plan.allowTrial &&
        !this.state.appliedPromoCode &&
        !(this.props.user && this.props.user.hasEverHadProSubscription) &&
        this.state.includeBasicTrial;

    return  plusTrialAvailable || basicTrialAvailable;
  }

  renderPlanEntry(plan: ProPlan) {
    let totalPrice = ProSubscriptionService.getProPlanPriceIncludingDiscount(
      plan,
      this.state.appliedPromoCode
    );
    let monthlyPrice =
      plan.billingPeriod === "yr"
        ? ProSubscriptionService.getMonthlyPrice(totalPrice)
        : totalPrice;

    let trialAvailable = this.isTrialAvailable(plan);

    return (
      <div key={plan.id} className="form-check mb-2 plan-option">
        <input
          className="form-check-input"
          type="radio"
          name="SelectedPlan"
          id={plan.id}
          checked={
            this.state.selectedProPlanId === plan.id ||
            (!this.state.selectedProPlanId && plan.id === "AnnualPlus")
          }
          onChange={() => this.onPlanChange(plan)}
          onClick={() => this.onPlanChange(plan)}
        />
        <label
          id={`${plan.id}-label`}
          className="form-check-label"
          htmlFor={plan.id}
        >
          {trialAvailable ? (
            <strong>Free Today&nbsp;</strong>
          ) : (
            <strong>{plan.name}&nbsp;</strong>
          )}
          <span className="pro-description-secondary">
            {trialAvailable && <span>{plan.preludeDescription}&nbsp;</span>}$
            {monthlyPrice}
            {plan.description}
            {plan.postDescriptionTrial == null
              ? plan.postDescription.length > 0
                ? ` $${totalPrice}. ${plan.postDescription}`
                : "."
              : ` $${totalPrice}. ${plan.postDescriptionTrial}`}
          </span>
        </label>
      </div>
    );
  }

  render() {
    if (this.state.redirectToSubscribe) {
      return (
        <Redirect to={{ pathname: "/subscribe", state: this.redirectState }} />
      );
    }

    var selectedProPlan = this.state.proPlans.find(
      (p) => p.id === this.state.selectedProPlanId
    );

    return (
      <div className="py-5">
        {FieldValidationError.hasGenericError(this.state.errors) && (
          <div
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{
              __html: FieldValidationError.getGenericErrorSummary(
                this.state.errors
              ),
            }}
          />
        )}
        <div className="steps">
          <div className="step active-step">1</div>
          <span id="crumb-one">Create Account</span>
          <div className="step inactive-step">2</div>
          <span id="crumb-two">Billing Info</span>
        </div>
        <div className="row">
          <div className="col-lg-8 order-12 order-lg-1">
            <form method="post" onSubmit={this.handleFormSubmit}>
              <div className="card mb-3">
                <div className="card-header primary-card-header">
                  <h5 id="form-title" className="text-white">
                    Create Your Account
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12 col-lg-6">
                      <div className="mb-3">
                        <label id="first-name-label" htmlFor="firstName">
                          First name
                        </label>
                        <input
                          type="text"
                          className={
                            FieldValidationError.isFieldInError(
                              this.state.errors,
                              "FirstName"
                            )
                              ? "form-control-lg form-control is-invalid"
                              : "form-control-lg form-control"
                          }
                          id="firstName"
                          required
                          value={this.state.firstName}
                          onChange={(e) =>
                            this.setState({ firstName: e.target.value })
                          }
                        />
                        <div
                          className="invalid-feedback"
                          dangerouslySetInnerHTML={{
                            __html: FieldValidationError.getFieldErrorSummary(
                              this.state.errors,
                              "FirstName"
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <div className="mb-3">
                        <label id="last-name-label" htmlFor="lastName">
                          Last name
                        </label>
                        <input
                          type="text"
                          className={
                            FieldValidationError.isFieldInError(
                              this.state.errors,
                              "LastName"
                            )
                              ? "form-control-lg form-control is-invalid"
                              : "form-control-lg form-control"
                          }
                          id="lastName"
                          required
                          value={this.state.lastName}
                          onChange={(e) =>
                            this.setState({ lastName: e.target.value })
                          }
                        />
                        <div
                          className="invalid-feedback"
                          dangerouslySetInnerHTML={{
                            __html: FieldValidationError.getFieldErrorSummary(
                              this.state.errors,
                              "LastName"
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label id="email-label" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="text"
                      className={
                        FieldValidationError.isFieldInError(
                          this.state.errors,
                          "Email"
                        )
                          ? "form-control-lg form-control is-invalid"
                          : "form-control-lg form-control"
                      }
                      id="email"
                      required
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                    <div
                      className="invalid-feedback"
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "Email"
                        ),
                      }}
                    />
                  </div>
                  <div id="phone-number-container" className="mb-3">
                    <label id="phone-number-label" htmlFor="phoneNumber">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      className={
                        FieldValidationError.isFieldInError(
                          this.state.errors,
                          "PhoneNumber"
                        )
                          ? "form-control-lg form-control is-invalid"
                          : "form-control-lg form-control"
                      }
                      id="phoneNumber"
                      required
                      value={this.state.phoneNumber}
                      onChange={(e) =>
                        this.setState({ phoneNumber: e.target.value })
                      }
                    />
                    <div
                      className="invalid-feedback"
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "PhoneNumber"
                        ),
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label id="password-label" htmlFor="password">
                      Password
                    </label>
                    <input
                      type="password"
                      className={
                        FieldValidationError.isFieldInError(
                          this.state.errors,
                          "Password"
                        )
                          ? "form-control-lg form-control is-invalid"
                          : "form-control-lg form-control"
                      }
                      id="password"
                      required
                      value={this.state.password}
                      onChange={this.handlePasswordChange}
                    />
                    <div
                      className="invalid-feedback"
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "Password"
                        ),
                      }}
                    />
                  </div>
                  <div className="d-flex d-flex-wrap">
                    <div className="w-75 py-1">
                      <div className="progress">
                        <div
                          id="progress-bar"
                          className={this.state.passwordStrengthClass}
                          style={{ width: this.state.passwordStrengthWidth }}
                          role="progressbar"
                        ></div>
                      </div>
                    </div>
                    <div className="px-2">
                      <p id="password-strength">
                        {this.state.passwordStrengthLabel}
                      </p>
                    </div>
                  </div>
                  <div className="mb-2">
                    <label id="terms-label">
                      <input
                        type="checkbox"
                        checked={this.state.agreeToTerms}
                        id="agreeToTerms"
                        onChange={(e) =>
                          this.setState({
                            agreeToTerms: !this.state.agreeToTerms,
                          })
                        }
                        required
                      />
                      &nbsp;I agree to SermonCentral's{" "}
                      <a
                        className="anchor-item"
                        href={`${this.props.config.mainSiteUrl}/content/termsandconditions?ref=Subscribe`}
                        target="_blank"
                      >
                        Terms of Service
                      </a>
                      &nbsp;and{" "}
                      <a
                        className="anchor-item"
                        href={`${this.props.config.mainSiteUrl}/content/privacy-policy?ref=Subscribe`}
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </label>
                  </div>
                  <button
                    id="submit-button"
                    className="btn btn-primary btn-block btn-lg mb-4"
                    type="submit"
                    disabled={!this.state.enableSubmit}
                  >
                    {this.state.enableSubmit && <>NEXT STEP</>}
                    {!this.state.enableSubmit && (
                      <>
                        PLEASE WAIT...
                        <div
                          className="spinner-border spinner-border-sm ml-4 mb-1"
                          role="status"
                        />
                      </>
                    )}
                  </button>

                  <p id="sign-in-link">
                    <Link to="/auth/sign-in">
                      Already have an account? Sign in
                    </Link>
                  </p>

                  <p id="contact-disclaimer" className="small">
                    Sign up for a new account and receive emails and special
                    offers from SermonCentral and our partners. Your privacy is
                    always respected. We will never share your email address
                    with any other organization.
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-4 order-1 order-lg-2">
            <div className="card mb-3">
              <div className="card-header secondary-card-header">
                <h5>
                  <span id="pro-title" className="text-white">
                    Your PRO Subscription
                  </span>
                </h5>
              </div>
              <div className="card-body">
                {!this.state.proSubscriptionSelectionExpanded &&
                  selectedProPlan && (
                    <>
                      {this.renderPlanEntry(selectedProPlan)}
                      &nbsp;
                      <a
                        id="change-link"
                        href="#"
                        onClick={(e) => this.onDeselectPlan(e)}
                      >
                        (change)
                      </a>
                    </>
                  )}

                {this.state.proSubscriptionSelectionExpanded &&
                  this.state.proPlans.map((plan) => this.renderPlanEntry(plan))}

                {this.state.proSubscriptionSelectionExpanded && (
                  <>
                    {!this.state.appliedPromoCode &&
                      this.state.promoCodeCollapsed && (
                        <div
                          id="apply-promo-link"
                          style={{ color: "#268aff", cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ promoCodeCollapsed: false });
                            EventTracking.CreateAccount.onPromoCodeLinkClick();
                          }}
                        >
                          Apply promo code{" "}
                          <FontAwesomeIcon icon={["fas", "angle-down"]} />
                        </div>
                      )}

                    {!this.state.appliedPromoCode &&
                      !this.state.promoCodeCollapsed && (
                        <form
                          onSubmit={this.handlePromoCodeFormSubmit}
                          className="mt-3"
                        >
                          <div className="input-group">
                            <input
                              id="promo-input"
                              type="text"
                              placeholder="Promo code"
                              className={
                                FieldValidationError.isFieldInError(
                                  this.state.errors,
                                  "PromoCode"
                                )
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              required
                              value={this.state.enteredPromoCode}
                              onChange={(e) =>
                                this.setState({
                                  enteredPromoCode: e.target.value,
                                })
                              }
                            />
                            <div className="input-group-append">
                              <button
                                id="promo-apply-button"
                                type="submit"
                                className="btn btn-secondary"
                                disabled={!this.state.enableSubmit}
                              >
                                Apply
                              </button>
                            </div>
                            <div
                              className="invalid-feedback"
                              dangerouslySetInnerHTML={{
                                __html:
                                  FieldValidationError.getFieldErrorSummary(
                                    this.state.errors,
                                    "PromoCode"
                                  ),
                              }}
                            />
                          </div>
                        </form>
                      )}
                  </>
                )}
              </div>
            </div>

            <div className="d-none d-lg-block">
              <div className="row py-5 mt-3">
                <div className="col-sm-3 mt-1 p-1">
                  <img className="img-fluid" src={SSLLogo} />
                </div>
                <div id="ssl-container" className="col-sm-9">
                  <h6>SSL Secure Connection</h6>
                  <small>
                    This a secure transaction with all information sent from our
                    registration page to our secure servers encrypted for your
                    protection.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
